﻿export class ImageSlider {

    private images: NodeListOf<HTMLImageElement> = null;
    private activeImage: HTMLImageElement;
    private navigation: HTMLElement;
    constructor() {

        if (document.querySelector('imageslider img') == null)
            return;

        this.images = document.querySelectorAll<HTMLImageElement>('imageslider img');
        this.navigation = document.querySelector<HTMLElement>('imageslider navigation');

        this.initialImageState();
        this.initEventHandlers();


    }
    private initEventHandlers() {
        document.querySelectorAll('[id^="nav"]')
            .forEach(element => {
                element.addEventListener('click', event => {
                    this.setActiveImage();
                })
            });

        window.addEventListener("scroll", event => {
            let yPos = 0 - window.pageYOffset / 2;

            this.navigation.style.top = yPos * 3 + "px";
            this.getActiveImage().style.top = -yPos + "px";
        });
    }
    getActiveImage(): HTMLImageElement {
        return document.querySelector<HTMLImageElement>('imageslider img.img_in');
    }
    private initialImageState() {

        this.images[0].classList.add('img_in');
        this.addTextOverlayForImage(this.images[0]);
    }

    private setActiveImage() {

        //this is only set by initialImageState, so just remove it and let hte navigation handle the logic

        let nextImage = this.getActiveImage().nextElementSibling as HTMLImageElement;
        let active = this.getActiveImage();

        if (nextImage != null) {
            let imageText = nextImage.closest('imageslider').querySelector('imagetext');
            nextImage.classList.add('img_in');
            nextImage.classList.remove('img_out');

            this.addTextOverlayForImage(nextImage);
            active.classList.remove('img_in');
            active.classList.add('img_out');
            nextImage.classList.remove('hidden');
            (imageText as HTMLElement).dataset["image"] = nextImage.id;
        }
        else {
            let imageText = this.images[0].closest('imageslider').querySelector('imagetext');

            this.images[0].classList.remove('img_out');
            this.images[0].classList.add('img_in');
            this.addTextOverlayForImage(this.images[0]);
            active.classList.remove('img_in');
            active.classList.add('img_out');
            (imageText as HTMLElement).dataset["image"] = this.images[0].id;


        }


    }
    elementOverlay: HTMLElement;
    private addTextOverlayForImage(element: HTMLImageElement) {

        if (this.elementOverlay != null)
            this.elementOverlay.classList.add('hidden')

        let activeImageId = element.id;
        this.elementOverlay = element.closest('imageslider').querySelector(`[data-img='${activeImageId}']`)

        this.elementOverlay.classList.remove('hidden');
    }


}
