﻿export default class NavVisibility {

    navbar = document.querySelector("nav");
    sticky = this.navbar.offsetTop;

    constructor() {

        window.addEventListener("scroll", event => {
            //this.setSticky();
        });

    }

    setSticky() {

        if (window.pageYOffset >= this.sticky) {
            this.navbar.classList.add("sticky")
        } else {
            this.navbar.classList.remove("sticky");
        }
    }
}