﻿import { supportForWebP } from "./ImageLoader";

export default class HandleNoWebP {

    constructor() {

        if (!supportForWebP()) {
            let elements = document.querySelectorAll('[data-fallback]');

            elements.forEach(element => {
                {
                    var img = (element as HTMLImageElement);

                    img.src = img.src
                        .replace('.webp', '.' + (img.dataset['fallback']))
                        .replace(img.baseURI, '');
                }
            });

            document.querySelectorAll('.webp')
                .forEach(element => element.classList.replace('webp', 'nowebp'));
        };
    }
}