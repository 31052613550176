﻿
export class Newsletter {

    constructor() {
        this.initEventHandlers();

        //document.addEventListener('scroll', function (e) {
        //    console.log(window.scrollY);
        //})

    }
     
    private initEventHandlers() {

        
        document.querySelector('#email')?.addEventListener("focus", event => {
            let hint = (event.target as HTMLElement).parentElement.previousElementSibling;
            hint.classList.remove('out')
            hint.classList.add('in')

        });

        document.querySelector('.newsletter button')?.addEventListener("click", event => {
            let element = document.getElementById('newsletter');
            let headerOffset = 220;
            let elementPosition = element.getBoundingClientRect().top;
            let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });

        });

        document.querySelector('#email')?.addEventListener("blur", event => {
            let hint = (event.target as HTMLElement).parentElement.previousElementSibling;
            hint.classList.remove('in')
            hint.classList.add('out')
        });
        document.querySelector(".notification")?.addEventListener("click", event => {
            (event.target as HTMLElement).parentElement.style.display = "none";
        });
        document.querySelector('newsletterform button')?.addEventListener('click', async event => {

            //if (!this.IsValidEmail())
            //    return;

            const response = await this.Post('/Index?handler=newsletter',
                {
                    Email: (document.querySelector('#email') as HTMLInputElement).value
                });

            let html = await response.text()
            {
                if (response.ok) {
                    let p = document.querySelector(".notification") as HTMLElement;
                    p.style.display = "flex";
                    // disableBodyScroll(p);

                    //fbq('track', 'CompleteRegistration');
                }
            }
        });

        //document.querySelector('#email')?.addEventListener("blur", event => {
        //    this.IsValidEmail();
        //});

    }

    private IsValidEmail(): boolean {
        let input = document.getElementById('email') as HTMLInputElement;

        let regexpEmail = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$');

        if (!regexpEmail.test(input.value) && input.value != "") {
            input.parentElement.nextElementSibling.querySelector('error').classList.add('show')
            return false;
        } else
            input.parentElement.nextElementSibling.querySelector('error').classList.remove('show')

        return true;
    }

    private async Post(request: RequestInfo, postData: Object): Promise<Response> {

        let reqToken = document.getElementsByName('__RequestVerificationToken')[0] as HTMLInputElement;

        return await fetch(request, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8', 'X-ANTI-FORGERY-TOKEN': reqToken.value},
            body: JSON.stringify(postData)
        });
    }


}
