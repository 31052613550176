﻿export default class ParallaxManager {

    parallaxHandlers = new Map<string, ParallaxHandler>();
    constructor() {
        document.addEventListener('parallaxLoaded', e => {
            this.handleEvent(e as CustomEvent<IntersectionObserverEntry>);
        })
    }

    handleEvent({ detail }: CustomEvent<IntersectionObserverEntry>) {

        if (detail.isIntersecting && !this.parallaxHandlers.has(detail.target.id))
            this.parallaxHandlers.set(detail.target.id, new ParallaxHandler(detail));

        if (!detail.isIntersecting && this.parallaxHandlers.has(detail.target.id)) {
            let ph = this.parallaxHandlers.get(detail.target.id);
            this.parallaxHandlers.delete(detail.target.id);
            ph.dispose();
            ph = null;
        }
    }
}

class ParallaxHandler {

    private bg = this.detail.target as HTMLElement;
    private pa = this.bg.querySelector("parallax-view") as HTMLElement;
    private listener: EventListener = () => this.positionParallax();

    constructor(private detail: IntersectionObserverEntry) {

        if (this.pa == null)
            console.error("Could not find element <parallax-view></parallax-view>");
        else
            window.addEventListener("scroll", this.listener);

    }
    dispose() {
        window.removeEventListener("scroll", this.listener);
    }
    positionParallax() {
        let bb = this.bg.getBoundingClientRect();

        if (bb.y < window.innerHeight) {
            if (bb.y > 0)
                this.pa.style.backgroundPositionY = `-${(bb.y) / 1.5}px`;
            else
                this.pa.style.backgroundPositionY = `${-bb.y / 1.5}px`;
        }
    }
}