﻿import * as imageSlider from './ImageSlider'
import * as newsletter from './Newsletter'
import HandleNoWebP from './HandleNoWebP'
import ParallaxManager from './ParallaxHandler';
import NavVisibility from './NavVisibility';
import ImageLoader from './ImageLoader';
import ActionPanel from './ActionPanel';

export class Application {

    constructor() {

        new HandleNoWebP();

        new imageSlider.ImageSlider();
        new newsletter.Newsletter();

        //document.fonts.onloadingdone = e => {
        //    document.querySelector('#header .brand').classList.add('visible');
        //}
        //setTimeout(() => { document.querySelector('#header .brand').classList.add('visible'); }, 500);
        
        new ParallaxManager();
        new NavVisibility();
        new ImageLoader();
        new ActionPanel();
    }
}

new Application();