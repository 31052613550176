﻿export default class ActionPanel {

    private menu = document.querySelector('nav-panel .menu');
    private menuItems = document.querySelector('nav-panel menu-items');
    private main = document.querySelector('div main');
    private state = '0';

    constructor() {
        this.setup();
    }

    private setup() {

        let menuHeight = this.menuItems.clientHeight;

        (this.menuItems as HTMLElement).style.top = menuHeight + 'px';

        this.menu.addEventListener('click', e => {
            (this.menuItems as HTMLElement).style.position = 'relative';
            let menuButton = (e.target as HTMLElement).closest('action');
            this.state = menuButton.querySelector('img').dataset['src'].split(':')[0];
            let srcToggle = menuButton.querySelector('img').dataset['src'].split(':')[1];

            if (this.state == '0') {
                (this.menuItems as HTMLElement).className = 'out';
                (this.menuItems as HTMLElement).style.top = menuHeight + 'px';
                menuButton.querySelector('img').dataset['src'] = '1:' + menuButton.querySelector('img').getAttribute('src');
                menuButton.querySelector('img').src = srcToggle;
            }

            if (this.state == '1') {
                (this.menuItems as HTMLElement).className = 'in';
                (this.menuItems as HTMLElement).style.top = '0px';
                menuButton.querySelector('img').dataset['src'] = '0:' + menuButton.querySelector('img').getAttribute('src')
                menuButton.querySelector('img').src = srcToggle;

            }
        });

        this.menuItems.addEventListener('transitionend', e => {
            if ((this.menuItems as HTMLElement).className == 'out')
                (this.menuItems as HTMLElement).style.removeProperty('position')
        });

        this.main.addEventListener('click', e => {
            if (this.state == '1')
                this.menu.dispatchEvent(new Event('click'));
        });


    }
}