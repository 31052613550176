﻿declare global {
    interface DocumentEventMap {
        'bgLoaded': CustomEvent<IntersectionObserverEntry>;
    }
}

export default class ImageLoader {

    //load background image by setting class as specified in bg-load
    //special handling for webp and nowebp -> implicit convention, it is required to use that specific naming to check for support
    //naming convention for webp or nowebp is same filename 
    //other image can also be loaded as is, just specify the class name 
    //only load when images are intersecting
    //check support for webp and set class accordingly
    supportForWebP = supportForWebP();
    constructor() {

        let options = {
            rootMargin: '50px',
            threshold: 0
        };

        let observer = new IntersectionObserver(this.handle, options);

        let images = document.querySelectorAll('section[bg-load]');

        images.forEach(img => observer.observe(img));


    }

    private handle(entries: IntersectionObserverEntry[], observer: any): void {

       
        entries.forEach(entry => {


            let bgLoad = entry.target.getAttribute("bg-load");
            if (bgLoad != null && entry.isIntersecting) {
                if (entry.isIntersecting && bgLoad == "webp")
                    if (supportForWebP)
                        entry.target.classList.add("webp");
                    else
                        entry.target.classList.add("nowebp");
                else if (entry.isIntersecting) {
                    entry.target.classList.add(bgLoad);
                }
                entry.target.removeAttribute("bg-load")
            }

            let bgEvent = entry.target.getAttribute("bg-event-onload");
            if (bgEvent != null)
                document.dispatchEvent(new CustomEvent(bgEvent, { detail: entry }));
        });
    }
}

export function supportForWebP(): boolean {
    let element = document.createElement('canvas')

    if (!!(element.getContext && element.getContext('2d'))) {
        return element.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    }
    return false;
}